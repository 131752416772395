<template>
  <div
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <div
      class="bg-white rounded-lg shadow-lg p-4 mx-4 sm:mx-auto max-w-xl w-full max-h-screen overflow-y-auto"
      style="width: 60%; max-width: 1200px"
    >
      <form @submit.prevent="proceed" @click.stop>
        <div class="space-y-12">
          <div class="border-b border-gray-900/10 pb-12">
            <h1 class="text-base font-semibold leading-7 text-gray-900">
              {{ id ? "Update" : "Add" }} User
            </h1>
            <Loading v-if="loading" />
            <div
              v-else
              class="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"
            >
              <div class="sm:col-span-3">
                <label
                  for="first-name"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >First name</label
                >
                <div class="mt-2">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autocomplete="given-name"
                    v-model="user.firstname"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label
                  for="last-name"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Last name</label
                >
                <div class="mt-2">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autocomplete="family-name"
                    v-model="user.lastname"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label
                  for="email-address"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Email address</label
                >
                <div class="mt-2">
                  <input
                    id="email-address"
                    name="email-address"
                    type="email"
                    autocomplete="email-address"
                    v-model="user.email"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label
                  for="phone-no"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Mobile</label
                >
                <div class="mt-2">
                  <input
                    id="phone-no"
                    maxlength="10"
                    name="phone-no"
                    type="text"
                    autocomplete="phone-no"
                    v-model="user.phoneno"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
              </div>

              <div class="col-span-full">
                <label
                  for="address1"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Address 1</label
                >
                <div class="mt-2">
                  <input
                    id="address1"
                    name="address1"
                    type="text"
                    autocomplete="address1"
                    v-model="user.address1"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
              </div>

              <div class="col-span-full">
                <label
                  for="address2"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Address 2</label
                >
                <div class="mt-2">
                  <textarea
                    id="address2"
                    name="address2"
                    rows="4"
                    v-model="user.address2"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label
                  for="city"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >City</label
                >
                <div class="mt-2">
                  <input
                    type="text"
                    name="city"
                    id="city"
                    autocomplete="address-level2"
                    v-model="user.city"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label
                  for="region"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >State</label
                >
                <div class="mt-2">
                  <input
                    type="text"
                    name="region"
                    id="region"
                    autocomplete="address-level1"
                    v-model="user.state"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label
                  for="postal-code"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Pincode</label
                >
                <div class="mt-2">
                  <input
                    type="text"
                    name="postal-code"
                    id="postal-code"
                    autocomplete="postal-code"
                    v-model="user.pincode"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label
                  for="totalamt"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Total Fees</label
                >
                <div class="mt-2">
                  <input
                    id="totalamt"
                    name="totalamt"
                    type="text"
                    autocomplete="totalamt"
                    v-model="user.totalamt"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
              </div>

              <div class="col-span-full" v-if="id ? true : false">
                <label
                  for="created"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Created Date (Actual Date of Joining)</label
                >
                <div class="mt-2">
                  <input
                    id="created"
                    name="created"
                    type="date"
                    autocomplete="created"
                    v-model="user.created"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
              </div>

              <div class="col-span-full" v-if="id ? true : false">
                <label
                  for="rejoindate"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Rejoin Date</label
                >
                <div class="mt-2">
                  <input
                    id="rejoindate"
                    name="rejoindate"
                    type="date"
                    autocomplete="rejoindate"
                    v-model="user.rejoindate"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
              </div>
              
              <div v-if="id" class="col-span-full">
                <label
                  for="desktop_cpu_brand"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Desktop CPU Brand</label
                >
                <div class="mt-2">
                  <input
                    id="desktop_cpu_brand"
                    name="desktop_cpu_brand"
                    type="text"
                    autocomplete="desktop_cpu_brand"
                    v-model="user.desktop_cpu_brand"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
              </div>
              <div v-if="id" class="col-span-full">
                <label
                  for="desktop_cpu_manufacturer"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Desktop CPU Manufacturer</label
                >
                <div class="mt-2">
                  <input
                    id="desktop_cpu_manufacturer"
                    name="desktop_cpu_manufacturer"
                    type="text"
                    autocomplete="desktop_cpu_manufacturer"
                    v-model="user.desktop_cpu_manufacturer"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
              </div>
              <div v-if="id" class="col-span-full">
                <label
                  for="desktop_cpu_model"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Desktop CPU Model</label
                >
                <div class="mt-2">
                  <input
                    id="desktop_cpu_model"
                    name="desktop_cpu_model"
                    type="text"
                    autocomplete="desktop_cpu_model"
                    v-model="user.desktop_cpu_model"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
              </div>
              <div v-if="id" class="col-span-full">
                <label
                  for="desktop_cpu_vendor"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Desktop CPU Vendor</label
                >
                <div class="mt-2">
                  <input
                    id="desktop_cpu_vendor"
                    name="desktop_cpu_vendor"
                    type="text"
                    autocomplete="desktop_cpu_vendor"
                    v-model="user.desktop_cpu_vendor"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
              </div>
              <div class="sm:col-span-3">
                <label
                  for="stat"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Status</label
                >
                <div class="mt-2">
                  <select
                    id="stat"
                    name="stat"
                    autocomplete="stat"
                    v-model="user.stat"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  >
                    <option value="1">Active</option>
                    <option value="0">Non-Active</option>
                  </select>
                </div>
              </div>
              <div class="sm:col-span-3">
                <label
                  for="password"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Password</label
                >
                <div class="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="text"
                    autocomplete="password"
                    v-model="user.password"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            class="text-sm font-semibold leading-6 text-gray-900"
            @click="closeModal"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
  
  <script>
import { mapActions, mapGetters } from "vuex";
import {convertDateToLocal} from "@/assets/js/common"
import moment from "moment"
import Loading from "../Loading.vue";
export default {
  name: "SetUser",
  props: {
    id: {
      type: Number,
      default: null,
    },
    editMode: {
      type: Boolean,
    },
  },
  components: {
    Loading,
  },
  data() {
    return {
      loading: true,
      user: {
        firstname: null,
        lastname: null,
        email: null,
        phoneno: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        pincode: null,
        password: null,
        totalamt: null,
        stat: 1,
        rejoindate: null,
        created : null,
        desktop_cpu_brand: null,
        desktop_cpu_manufacturer: null,
        desktop_cpu_model: null,
        desktop_cpu_vendor: null,
      },
    };
  },
  computed: {
    ...mapGetters(["fetchUser", "fetchToken"]),
  },
  methods: {
    ...mapActions(["createNewUser", "updateUser", "loadUser"]),
    proceed() {
      if (this.id) {
        this.updateUser({
          id: this.id,
          user: this.user,
          token: this.fetchToken,
        })
          .then(() => {
            this.$toast.open({
              message: "User updated successfully",
              type: "success",
            });
            this.$emit("close");
          })
          .catch((err) => {
            this.$toast.open({
              message: err.response.data.msg,
              type: "error",
            });
            this.$emit("close");
          });
      } else {
        this.createNewUser({
          user: this.user,
          token: this.fetchToken,
        })
          .then(() => {
            this.$toast.open({
              message: "User created successfully",
              type: "success",
            });
            this.$emit("close");
          })
          .catch((err) => {
            this.$toast.open({
              message: err.response.data.msg,
              type: "error",
            });
            this.$emit("close");
          });
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
  watch: {
    fetchUser(new_val) {
      for (let key in this.user) {
        let value = new_val[key];
        if(key === "rejoindate" || key === "created"){
          if(value && value.length){
            this.user[key] = moment(value).format("YYYY-MM-DD")
          }
          
        }else{
          this.user[key] = value;
        }
      }
    },
  },
  async created() {
    if (this.id) {
      this.loadUser({ id: this.id, token: this.fetchToken })
        .then(() => {
          this.loading = false;
          console.log("User loaded successfully");
        })
        .catch((err) => {
          this.loading = false;
          console.log("Error: ", err);
        });
    } else this.loading = false;
  },
};
</script>