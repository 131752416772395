<template>
  <div>
    <!-- <video class="rounded" :id="playerId" controls></video> -->
    <div style="position:relative;padding-top:56.25%;"><iframe
        :src="`https://iframe.mediadelivery.net/embed/${videoLibraryId}/${videourl}?autoplay=true&loop=false&muted=false&preload=true&responsive=true`"
        loading="lazy" style="border:0;position:absolute;top:0;height:100%;width:100%;"
        allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe>
    </div>
  </div>
</template>

<script>
// import Hls from "hls.js";
// import { baseVideoUrl } from "@/assets/js/config";

export default {
  name: "ShowVideo",
  props: {
    videourl: {
      type: String,
    },
  },
  data() {
    return {
      playerId: `video-player-${Math.random().toString(36)}`, // Generate a unique player ID
      videoLibraryId : process.env.VUE_APP_LIBRARY_ID
    };
  },
  // watch: {
  //   videourl: "initializeVideoPlayer",
  // },
  methods: {
    // initializeVideoPlayer() {
    //   const url = baseVideoUrl + this.videourl;
    //   if (url && url.trim() !== "") {
    //     const dom_video_element = document.getElementById(this.playerId);
    //     if (Hls.isSupported()) {
    //       const hls = new Hls();
    //       hls.loadSource(url);
    //       hls.attachMedia(dom_video_element);
    //       hls.on(Hls.Events.MANIFEST_PARSED, function () { });
    //     }
    //   }
    // },
  },
  mounted() {
    // Call the initialization method when the component is mounted
    // this.initializeVideoPlayer();
    // console.log({ g: this.videourl })
  },
};
</script>

<style>
/* Add your CSS styles here if needed */
</style>