<template>
  <div
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <div
      class="bg-white rounded-lg shadow-lg p-4 mx-4 sm:mx-auto max-w-xl w-full max-h-screen overflow-y-auto"
      style="width: 60%; max-width: 1200px"
      @click.stop
    >
      <div>
        <h1 class="text-base font-semibold leading-7 text-gray-900">
          Assign Category
        </h1>
        <Loading v-if="loading" />
        <div v-else class="py-4 pb-60 bg-white rounded-lg">
          <VueMultiselect
            v-model="video"
            :options="options"
            :multiple="true"
            :close-on-select="false"
            placeholder="Pick some"
            label="label"
            track-by="value"
          />
        </div>
        <div class="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            class="text-sm font-semibold leading-6 text-gray-900"
            @click="closeAssignModal"
          >
            Cancel
          </button>
          <button
            type="button"
            @click="proceed"
            class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "../Loading.vue";
export default {
  name: "AssignVideo",
  props: {
    id: {
      type: String,
      default: null,
    },
    assignMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: true,
      filter: {
        title: "",
      },
      video: [],
      options: [],
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["fetchVideos", "fetchAssignedVideos", "fetchToken"]),
  },
  methods: {
    ...mapActions(["loadVideos", "assignVideos", "getAssignedVideos"]),
    proceed() {
      const newVideos = this.video.map((obj) => obj.value);
      if (this.id) {
        this.assignVideos({
          id: this.id,
          videos: newVideos,
          token: this.fetchToken,
        })
          .then(() => {
            this.$toast.open({
              message: "Videos assigned successfully",
              type: "success",
            });
            this.$emit("end");
          })
          .catch((err) => {
            this.$toast.open({
              message: "Something went wrong, please try again!",
              type: "error",
            });
            this.$emit("end");
          });
      }
    },
    closeAssignModal() {
      this.$emit("end");
    },
  },
  watch: {
    fetchVideos(new_val) {
      for (const item of new_val) {
        const newObj = {};
        newObj["label"] = item.title;
        newObj["value"] = item.id;
        this.options.push(newObj);
      }
    },
    fetchAssignedVideos(new_val) {
      for (const item of new_val) {
        const newObj = {};
        newObj["label"] = item.title;
        newObj["value"] = item.id;
        this.video.push(newObj);
      }
    },
  },
  async created() {
    this.loadVideos({ filter: this.filter, token: this.fetchToken })
      .then(() => {
        this.loading = false;
        console.log("Video loaded successfully");
      })
      .catch((err) => {
        this.loading = false;
        console.log("Error: ", err);
      });
    if (this.id) {
      const id = this.id;
      this.getAssignedVideos({ id, token: this.fetchToken });
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
