const moment = require("moment");
function showDate(datetime) {
  return moment(datetime).format("DD/MM/YYYY");
}

function showDateInWords() {
  const inputDateString = "2021-12-10T07:34:30.000Z";

  // Create a Date object from the input string
  const date = new Date(inputDateString);

  // Define months as an array for formatting
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get date components
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Create the formatted string
  const formattedDateString = `${day} ${month} ${year} at ${hours}:${minutes}:${seconds}`;

  console.log(formattedDateString);
}

export default {
  showDate,
  showDateInWords
};
