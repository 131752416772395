<template>
  <div class="sm:px-2 lg:px-0">
    <div class="backdrop" v-if="modalVisible" @click="closeModal">
      <set-video
        @close="closeModal"
        v-if="modalVisible"
        :editMode="isEditMode"
        :id="id"
      />
    </div>
    <div class="backdrop" v-if="deleteModalVisible1" @click="deleteCloseModal1">
      <delete-video-1
        @close="deleteCloseModal1"
        @delete="deleteModal1"
        v-if="deleteModalVisible1"
      />
    </div>
    <div class="backdrop" v-if="deleteModalVisible2" @click="deleteCloseModal2">
      <delete-video-2
        @close="deleteCloseModal2"
        v-if="deleteModalVisible2"
        :id="id"
      />
    </div>
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-2xl font-semibold leading-6 text-gray-900">Video</h1>
      </div>
      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <button
          type="button"
          @click="openModalForCreate"
          class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Add video
        </button>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 -mx-4">
      <div class="bg-white rounded-lg p-4">
        <label
          for="title"
          class="block text-sm font-medium leading-6 text-gray-900"
          >Title</label
        >
        <div class="mt-2 flex rounded-md shadow-sm">
          <div class="relative flex flex-grow items-stretch focus-within:z-10">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <MagnifyingGlassIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              v-model="filter.title"
              type="text"
              name="title"
              id="title"
              class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Title"
              @keydown.enter="filterBy"
            />
          </div>
          <button
            type="button"
            @click="filterBy"
            class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Search
          </button>
        </div>
      </div>
    </div>
    <div class="flow-root">
      <div class="-mx-4 -my-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  SNo
                </th>
                <th
                  scope="col"
                  class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Title
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Type
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Order
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Created
                </th>
                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  <span class="sr-only">Actions</span>
                </th>
              </tr>
            </thead>
            <Loading v-if="loading" />
            <tbody v-else class="divide-y divide-gray-200">
              <tr v-for="(video, index) in fetchVideos" :key="video.id">
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-0"
                >
                  {{ index + 1 }}
                </td>
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-0"
                >
                  {{ video.title }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {{ videotypeconvert(video.type) }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {{ video.ord }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {{ showDateInWords(video.created) }}
                </td>
                <td
                  class="flex relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0 gap-2"
                >
                  <button
                    @click="openModalForEdit(video.id)"
                    class="bg-indigo-600 hover:bg-indigo-900 text-white py-2 px-2 rounded font-semibold"
                  >
                    <PencilSquareIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button
                    @click="deleteTheVideo(video.id)"
                    class="bg-red-600 hover:bg-red-900 text-white font-semibold py-2 px-2 rounded"
                  >
                    <TrashIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import SetVideo from "@/components/Video/SetVideo.vue";
import DeleteVideo1 from "@/components/Video/DeleteVideo1.vue";
import DeleteVideo2 from "@/components/Video/DeleteVideo2.vue";
import Loading from "@/components/Loading.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Videos",
  components: {
    SetVideo,
    Loading,
    DeleteVideo1,
    DeleteVideo2,
  },
  data() {
    return {
      filter: {
        title: "",
      },
      loading: true,
      modalVisible: false,
      deleteModalVisible1: false,
      deleteModalVisible2: false,
      isEditMode: false,
      id: null,
    };
  },
  computed: {
    ...mapGetters(["fetchVideos", "fetchToken"]),
  },
  methods: {
    ...mapActions(["loadVideos", "deleteVideo"]),
    openModalForCreate() {
      this.isEditMode = false; // Set to Create mode
      this.id = null;
      this.modalVisible = true;
    },
    openModalForEdit(videoId) {
      this.isEditMode = true; // Set to Edit mode
      this.id = videoId;
      this.modalVisible = true;
    },
    deleteTheVideo(videoId) {
      this.id = videoId;
      this.deleteModalVisible1 = true;
    },
    closeModal() {
      this.modalVisible = false;
    },
    deleteCloseModal1() {
      this.deleteModalVisible1 = false;
    },
    deleteModal1() {
      this.deleteModalVisible1 = false;
      this.deleteModalVisible2 = true;
    },
    deleteCloseModal2() {
      this.deleteModalVisible2 = false;
    },
    showDateInWords(input) {
      const date = new Date(input);
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      const formattedDateString = `${day} ${month} ${year} at ${hours}:${minutes}:${seconds}`;
      return formattedDateString;
    },
    filterBy() {
      this.loadVideos({ filter: this.filter, token: this.fetchToken })
        .then(() => {
          this.loading = false;
          console.log("Video loaded successfully");
        })
        .catch((err) => {
          this.loading = false;
          console.log("Error: ", err);
        });
    },
    videotypeconvert(type) {
      if (type == "F") {
        return "Free";
      }
      return "Paid";
    },
  },
  created() {
    this.loadVideos({ filter: this.filter, token: this.fetchToken })
      .then(() => {
        this.loading = false;
        console.log("Video loaded successfully");
      })
      .catch((err) => {
        this.loading = false;
        console.log("Error: ", err);
      });
  },
};
</script>
  
<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 999; /* Ensure the backdrop is on top of everything */
}
</style>