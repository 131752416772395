<template>
  <div
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <div
      class="bg-white rounded-lg shadow-lg p-4 mx-4 sm:mx-auto max-w-xl w-full max-h-screen overflow-y-auto"
      style="width: 60%; max-width: 1200px"
    >
      <!-- Your form content goes here -->
      <form @submit.prevent="proceed" @click.stop>
        <div class="space-y-12">
          <div class="border-b border-gray-900/10 pb-12">
            <h1 class="text-base font-semibold leading-7 text-gray-900">
              {{ id ? "Update" : "Add" }} Category
            </h1>
            <Loading v-if="loading" />
            <div
              v-else
              class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"
            >
              <div class="col-span-full">
                <label
                  for="catname"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Category Name</label
                >
                <div class="mt-2">
                  <input
                    type="text"
                    name="catname"
                    id="catname"
                    autocomplete="catname"
                    v-model="category.catname"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
              </div>

              <div class="col-span-full">
                <label
                  for="ord"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Order</label
                >
                <div class="mt-2">
                  <input
                    id="ord"
                    name="ord"
                    type="text"
                    autocomplete="ord"
                    v-model="category.ord"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
              </div>

              <div class="col-span-full">
                <label
                  for="remarks"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Remarks</label
                >
                <div class="mt-2">
                  <textarea
                    id="remarks"
                    name="remarks"
                    rows="3"
                    v-model="category.remarks"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            class="text-sm font-semibold leading-6 text-gray-900"
            @click="closeModal"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
      
  <script>
import { mapActions, mapGetters } from "vuex";
import Loading from "../Loading.vue";

export default {
  name: "SetCategory",
  props: {
    id: {
      type: Number,
      default: null,
    },
    editMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: true,
      category: {
        id: null,
        catname: null,
        remarks: null,
        ord: null,
      },
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["fetchCategory", "fetchToken", "fetchCatOrd"]),
  },
  methods: {
    ...mapActions([
      "createNewCategory",
      "updateCategory",
      "loadCategory",
      "loadCatOrd",
    ]),
    proceed() {
      if (this.id) {
        //For update
        this.updateCategory({
          id: this.id,
          category: this.category,
          token: this.fetchToken,
        })
          .then(() => {
            this.$toast.open({
              message: "Category updated successfully",
              type: "success",
            });
            this.$emit("close");
          })
          .catch((err) => {
            this.$toast.open({
              message: err.response.data.msg,
              type: "error",
            });
            this.$emit("close");
          });
      } else {
        this.createNewCategory({
          category: this.category,
          token: this.fetchToken,
        })
          .then(() => {
            this.$toast.open({
              message: "Category created successfully",
              type: "success",
            });
            this.$emit("close");
          })
          .catch((err) => {
            this.$toast.open({
              message: err.response.data.msg,
              type: "error",
            });
            this.$emit("close");
          });
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
  watch: {
    fetchCategory(new_val) {
      for (let key in this.category) {
        let value = new_val[key];
        this.category[key] = value;
      }
    },
    fetchCatOrd(new_val) {
      if (!this.id) {
        this.category.ord = Math.floor(new_val) + 1;
      }
    },
  },
  async created() {
    if (!this.id) {
      this.loadCatOrd({ token: this.fetchToken })
        .then(() => {
          this.category.ord = Math.floor(this.fetchCatOrd) + 1;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log("Error: ", err);
        });
    } else {
      this.loadCategory({ id: this.id, token: this.fetchToken })
        .then(() => {
          this.loading = false;
          console.log("Category loaded successfully");
        })
        .catch((err) => {
          this.loading = false;
          console.log("Error: ", err);
        });
    }
  },
};
</script>