const axios = require('axios')
const $http = axios.create({
    baseURL : process.env.VUE_APP_BASE_URL
})

const state = {
    categorys:[],
    category:{},
	videos:[],
	ord:null
}

const getters = {
    fetchCategorys : (state) => state.categorys,
	fetchCategory: (state) => state.category,	
	fetchAssignedVideos: (state) => state.videos,
	fetchCatOrd: (state) => state.ord	
}

const actions = {
    loadCategorys({commit}, {filter, token}){
		const query_string = new URLSearchParams(filter).toString();
		return $http.get(`category?${query_string}`, {
			headers : {
				'x-auth-token' : token
			}
		}).then(response => {
			commit('SET_CATEGORYS', response.data.data)
		})
	},
	loadCategory({ commit }, { id , token}) {
		return $http.get(`category/${id}`, {
			headers : {
				'x-auth-token' : token
			}
		}).then((response) => {
			commit('SET_CATEGORY', response.data.data)
		})
	},
	createNewCategory({commit}, {category , token}){
		return $http.post('category', category, {
			headers : {
				'x-auth-token' : token
			}
		}).then((response) => {
			commit('ADD_CATEGORY', response.data.data)
		})
	},
	updateCategory({ commit }, { id, category, token}) {
		return $http.put(`category/${id}`, category, {
			headers : {
				'x-auth-token' : token
			}
		}).then((response) => {
			commit('UPDATE_CATEGORY', response.data.data)
		})
	},
	deleteCategory({ commit }, {id, token}){
		return $http.delete(`category/${id}`, {
			headers : {
				'x-auth-token' : token
			}
		}).then(() => {
			commit('DELETE_CATEGORY', id)	
		})
	},
	getAssignedVideos({ commit }, {id, token}){
		return $http.get(`category/${id}/assign`, {
			headers : {
				'x-auth-token' : token
			}
		}).then(response => {
			commit('ASSIGN_VIDEOS', response.data.data)
		})	
	},
	assignVideos({ commit }, {id, videos , token}){
		return $http.put(`category/${id}/assign`, videos, {
			headers : {
				'x-auth-token' : token
			}
		}).then(response => {
			commit('UPDATE_VIDEOS', response.data.data)
		})	
	},
	loadCatOrd({commit}, {token}) {
		return $http.get(`category/ord`, {
			headers : {
				'x-auth-token' : token
			}
		}).then((response) => {
			commit('SET_ORD', response.data.data[0].max)
		})
	},
}

const mutations = {
    SET_CATEGORYS : (state, categorys) => {
		state.categorys = categorys
	},
	SET_CATEGORY : (state, category) => {
		state.category = category
	},
	UPDATE_CATEGORY: (state, category) => {
		state.category = category
		state.categorys = state.categorys.map(c => (c.id === category.id) ? category : c)
	},
	ADD_CATEGORY : (state, category) => {
		state.categorys.push(category)
	},
	DELETE_CATEGORY : (state, id) => {
		state.categorys = state.categorys.filter(c => c.id !== id)
	},
	ASSIGN_VIDEOS : (state, videos) => {
		state.videos = videos
	},
	UPDATE_VIDEOS : (state, videos) => {
	},
	SET_ORD : (state, ord) => {
		state.ord = ord
	},
}

export default {
	state, getters, actions, mutations
}