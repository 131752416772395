<template>
  <div class="px-4 sm:px-2 lg:px-0">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-2xl font-semibold leading-6 text-gray-900">Category</h1>
      </div>
      <div class="backdrop" v-if="deleteModalVisible" @click="deleteCloseModal">
        <delete-category
          @close="deleteCloseModal"
          v-if="deleteModalVisible"
          :id="id"
        />
      </div>
      <div class="backdrop" v-if="modalVisible" @click="closeModal">
        <set-category
          @close="closeModal"
          v-if="modalVisible"
          :editMode="isEditMode"
          :id="id"
        />
      </div>
      <div class="backdrop" v-if="assignModalVisible" @click="closeAssignModal">
        <assign-video
          @end="closeAssignModal"
          v-if="assignModalVisible"
          :assignMode="isAssignMode"
          :id="id"
        />
      </div>
      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <button
          type="button"
          @click="openModalForCreate"
          class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Add category
        </button>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 -mx-4">
      <div class="bg-white rounded-lg p-4">
        <label
          for="catname"
          class="block text-sm font-medium leading-6 text-gray-900"
          >Title</label
        >
        <div class="mt-2 flex rounded-md shadow-sm">
          <div class="relative flex flex-grow items-stretch focus-within:z-10">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <MagnifyingGlassIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              v-model="filter.catname"
              type="text"
              name="catname"
              id="catname"
              class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Title"
              @keydown.enter="filterBy"
            />
          </div>
          <button
            type="button"
            @click="filterBy"
            class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Search
          </button>
        </div>
      </div>
    </div>
    <div class="flow-root">
      <div class="-mx-4 -my-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  SNo
                </th>
                <th
                  scope="col"
                  class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Title
                </th>
                <th
                  scope="col"
                  class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Order
                </th>
                <th
                  scope="col"
                  class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Remarks
                </th>
                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  <span class="sr-only">Actions</span>
                </th>
              </tr>
            </thead>
            <Loading v-if="loading" />
            <tbody v-else class="divide-y divide-gray-200">
              <tr
                v-for="(category, index) in fetchCategorys"
                :key="category.id"
              >
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-0"
                >
                  {{ index + 1 }}
                </td>
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-0"
                >
                  {{ category.catname }}
                </td>
                <td class="whitespace-nowrap py-4 text-sm text-gray-500">
                  {{ category.ord }}
                </td>
                <td class="whitespace-nowrap py-4 text-sm text-gray-500">
                  {{ category.remarks }}
                </td>
                <td
                  class="flex relative whitespace-nowrap py-4 text-center text-sm font-medium sm:pr-0 gap-2"
                >
                  <button
                    @click="openModalForAssign(category.id)"
                    class="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-2 rounded"
                  >
                    <VideoCameraIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button
                    @click="openModalForEdit(category.id)"
                    class="bg-indigo-600 hover:bg-indigo-900 text-white py-2 px-2 rounded font-semibold"
                  >
                    <PencilSquareIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button
                    @click="deleteTheCategory(category.id)"
                    class="bg-red-600 hover:bg-red-900 text-white font-semibold py-2 px-2 rounded"
                  >
                    <TrashIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import SetCategory from "@/components/Category/SetCategory.vue";
import AssignVideo from "@/components/Category/AssignVideo.vue";
import Loading from "@/components/Loading.vue";
import DeleteCategory from "@/components/Category/DeleteCategory.vue";

import { mapActions, mapGetters } from "vuex";
export default {
  name: "Categories",
  components: { SetCategory, AssignVideo, Loading, DeleteCategory },
  data() {
    return {
      filter: {
        catname: "",
      },
      loading: true,
      assignModalVisible: false,
      deleteModalVisible: false,
      isAssignMode: false,
      modalVisible: false,
      isEditMode: false,
      id: null,
    };
  },
  computed: {
    ...mapGetters(["fetchCategorys", "fetchToken"]),
  },
  methods: {
    ...mapActions(["loadCategorys", "deleteCategory"]),
    openModalForCreate() {
      this.isEditMode = false;
      this.id = null;
      this.modalVisible = true;
    },
    openModalForEdit(catId) {
      this.isEditMode = true; // Set to Edit mode
      this.id = catId;
      this.modalVisible = true;
    },
    openModalForAssign(catId) {
      this.isAssignMode = true; // Set to Edit mode
      this.id = catId;
      this.assignModalVisible = true;
    },
    deleteTheCategory(catId) {
      this.id = catId;
      this.deleteModalVisible = true;
    },
    deleteCloseModal() {
      this.deleteModalVisible = false;
    },
    closeAssignModal() {
      this.assignModalVisible = false;
    },
    closeModal() {
      this.modalVisible = false;
    },
    filterBy() {
      this.loadCategorys({ filter: this.filter, token: this.fetchToken })
        .then(() => {
          this.loading = false;
          console.log("Categories loaded successfully");
        })
        .catch((err) => {
          this.loading = false;
          console.log("Error: ", err);
        });
    },
  },
  created() {
    this.loadCategorys({ filter: this.filter, token: this.fetchToken })
      .then(() => {
        this.loading = false;
        console.log("Categories loaded successfully");
      })
      .catch((err) => {
        this.loading = false;
        console.log("Error: ", err);
      });
  },
};
</script>
  
<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 999; /* Ensure the backdrop is on top of everything */
}
</style>