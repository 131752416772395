<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="relative z-50 lg:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div
                  class="absolute left-full top-0 flex w-16 justify-center pt-5"
                >
                  <button
                    type="button"
                    class="-m-2.5 p-2.5"
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div
                class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10"
              >
                <div class="flex h-16 shrink-0 items-center">
                  <img
                    class="h-auto w-40"
                    src="@/assets/img/logo.jpg"
                    alt="Your Company"
                  />
                </div>
                <nav class="flex flex-1 flex-col">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in navigation" :key="item.name">
                          <router-link :to="item.href">
                            <a
                              :href="item.href"
                              :class="[
                                item.current
                                  ? 'bg-gray-800 text-white'
                                  : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                              ]"
                            >
                              <component
                                :is="item.icon"
                                class="h-6 w-6 shrink-0"
                                aria-hidden="true"
                              />
                              {{ item.name }}
                            </a>
                          </router-link>
                        </li>
                        <li class="mt-auto">
                          <button
                            @click="logout"
                            class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                          >
                            <ArrowRightOnRectangleIcon
                              class="h-6 w-6 shrink-0"
                              aria-hidden="true"
                            />
                            Logout
                          </button>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div
      class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4"
      >
        <div class="flex h-16 shrink-0 items-center">
          <img
            class="h-auto w-40"
            src="@/assets/img/logo.jpg"
            :alt="welcomeMessage"
          />
        </div>
        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="item in navigation" :key="item.name">
                  <router-link :to="item.href">
                    <a
                      :href="item.href"
                      :class="[
                        item.current
                          ? 'bg-gray-800 text-white'
                          : 'text-gray-400 hover:text-white hover:bg-gray-800',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                      ]"
                    >
                      <component
                        :is="item.icon"
                        class="h-6 w-6 shrink-0"
                        aria-hidden="true"
                      />
                      {{ item.name }}
                    </a>
                  </router-link>
                </li>
                <li class="mt-auto">
                  <button
                    @click="logout"
                    class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                  >
                    <ArrowRightOnRectangleIcon
                      class="h-6 w-6 shrink-0"
                      aria-hidden="true"
                    />
                    Logout
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="lg:pl-72">
      <div
        class="top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 bg-white px-4 sm:gap-x-6 sm:px-6 lg:px-8"
      >
        <button
          type="button"
          class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <h1 class="text-xl font-semibold text-black-500">
          {{ welcomeMessage }}
        </h1>
        <h1 class="text-xl font-semibold text-black-500">
          Total Fees: {{ fetchSum }}
        </h1>
      </div>

      <main>
        <div class="px-4 sm:px-6 lg:px-8">
          <router-view />
        </div>
      </main>
    </div>
  </div>
</template>

<script >
import { ref } from "vue";
import {
  VideoCameraIcon,
  HomeIcon,
  UsersIcon,
  ViewColumnsIcon,
} from "@heroicons/vue/24/outline";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SideBar",
  data() {
    return {
      welcomeMessage: process.env.VUE_APP_SOFTWARE_NAME,
      navigation: [
        { name: "Dashboard", href: "/", icon: HomeIcon, current: true },
        { name: "Users", href: "/users", icon: UsersIcon, current: false },
        {
          name: "Videos",
          href: "/videos",
          icon: VideoCameraIcon,
          current: false,
        },
        {
          name: "Categories",
          href: "/categories",
          icon: ViewColumnsIcon,
          current: false,
        },
      ],
      teams: [],
      userNavigation: [
        // { name: "Your profile", href: "#" },
        // { name: "Sign out", href: "#" },
      ],

      sidebarOpen: ref(false),
    };
  },
  computed: {
    ...mapGetters(["fetchSum", "fetchToken"]),
  },
  methods: {
    ...mapActions(["logoutUser", "loadSum"]),
    logout() {
      console.log("logout");
      this.logoutUser()
        .then(() => {
          this.$toast.open({
            message: "User logged out successfully",
            type: "success",
          });
          this.$router.push({ name: "login" });
        })
        .catch((err) => {
          this.$toast.open({
            message: "Something went wrong, please try again!",
            type: "success",
          });
        });
    },
  },
  created() {
    this.loadSum({ token: this.fetchToken });
  },
};
</script>