<template>
  <div
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <div
      class="bg-white rounded-lg shadow-lg p-4 mx-4 sm:mx-auto max-w-xl w-full max-h-screen overflow-y-auto"
      style="width: 60%; max-width: 1200px"
      @click.stop
    >
      <div>
        <h1 class="text-base font-semibold leading-7 text-gray-900">
          Assign Category
        </h1>
        <Loading v-if="loading" />

        <div v-else class="py-4 pb-40 bg-white rounded-lg">
          <VueMultiselect
            v-model="category"
            :options="options"
            :multiple="true"
            :close-on-select="false"
            placeholder="Pick some"
            label="label"
            track-by="value"
          />
        </div>
        <div class="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            class="text-sm font-semibold leading-6 text-gray-900"
            @click="closeAssignModal"
          >
            Cancel
          </button>
          <button
            type="button"
            @click="proceed"
            class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import { mapActions, mapGetters } from "vuex";
import Loading from "../Loading.vue";
export default {
  name: "AssignUserCategory",
  props: {
    id: {
      type: String,
      default: null,
    },
    assignMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: true,
      filter: {
        catname: "",
      },
      category: [],
      options: [],
    };
  },
  computed: {
    ...mapGetters([
      "fetchCategorys",
      "fetchAssignedUserCategorys",
      "fetchToken",
    ]),
  },
  components: {
    Loading,
  },
  methods: {
    ...mapActions([
      "loadCategorys",
      "assignUserCategorys",
      "getAssignedUserCategorys",
    ]),
    proceed() {
      const newCategorys = this.category.map((obj) => obj.value);
      if (this.id) {
        //For update
        this.assignUserCategorys({
          id: this.id,
          categorys: newCategorys,
          token: this.fetchToken,
        })
          .then(() => {
            this.$toast.open({
              message: "Categorys assigned successfully",
              type: "success",
            });
            this.$emit("end");
            console.log("Categorys assigned successfully");
          })
          .catch((err) => {
            this.$toast.open({
              message: "Something went wrong, please try again!",
              type: "error",
            });
            this.$emit("end");
          });
      }
    },
    closeAssignModal() {
      this.$emit("end");
    },
  },
  watch: {
    fetchCategorys(new_val) {
      for (const item of new_val) {
        const newObj = {};
        newObj["label"] = item.catname;
        newObj["value"] = item.id;
        this.options.push(newObj);
      }
    },
    fetchAssignedUserCategorys(new_val) {
      for (const item of new_val) {
        const newObj = {};
        newObj["label"] = item.catname;
        newObj["value"] = item.id;
        this.category.push(newObj);
      }
    },
  },
  async created() {
    this.loadCategorys({ filter: this.filter, token: this.fetchToken })
      .then(() => {
        this.loading = false;
        console.log("Category loaded successfully");
      })
      .catch((err) => {
        this.loading = false;
        console.log("Error: ", err);
      });
    if (this.id) {
      const id = this.id;
      this.getAssignedUserCategorys({ id, token: this.fetchToken })
        .then(() => {
          this.loading = false;
          console.log("Category loaded successfully");
        })
        .catch((err) => {
          this.loading = false;
          console.log("Error: ", err);
        });
    }
  },
};
</script>
  <style src="vue-multiselect/dist/vue-multiselect.css"></style>
    