<template>
  <div
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <div
      class="bg-white rounded-lg shadow-lg p-4 mx-4 sm:mx-auto max-w-xl w-full max-h-screen overflow-y-auto"
      style="width: 60%; max-width: 1200px"
      @click.stop
    >
      <div>
        <div
          class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100"
        >
          <ExclamationTriangleIcon class="h-6 w-6 text-red-600" />
        </div>
        <div class="mt-3 border-2 p-4 rounded-lg text-center sm:text-left">
          <h3 class="text-base font-semibold leading-6 text-gray-900">
            Delete Category
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              Are you sure you want to delete this category? This action cannot
              be undone.
            </p>
          </div>
        </div>

        <div class="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
            @click="deleteTheCategory"
          >
            Delete
          </button>
          <button
            type="button"
            class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            @click="closeModal"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
    
  <script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "DeleteCategory",
  data() {
    return {};
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters(["fetchToken"]),
  },
  methods: {
    ...mapActions(["deleteCategory"]),
    deleteTheCategory() {
      this.deleteCategory({ id: this.id, token: this.fetchToken })
        .then(() => {
          this.$toast.open({
            message: "Category deleted successfully",
            type: "success",
          });
          this.$emit("close");
        })
        .catch((err) => {
          this.$toast.open({
            message: err.response.data.msg,
            type: "error",
          });
          this.$emit("close");
        });
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>